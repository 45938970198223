<template>
    <SimpleTable formclass="Clients/form" endpoint="clients" :aditionalColumns="headers">
        <template>
            <h1>Cadastro de Clientes</h1>
            <v-divider class="mb-4"></v-divider>
        </template>

        <template v-slot:extra-column="item">
            <v-chip class="ma-0" outlined color="success" v-if="item.birthDate"> {{ $moment(item.birthDate).format("DD/MM/YYYY") }} </v-chip>
        </template>

        <template v-slot:more-btn="item">
            <v-chip class="ma-0" outlined color="error" v-if="!item.motherName">
                <v-avatar left> <v-icon>error</v-icon> </v-avatar>
                Necessário atualizar
            </v-chip>
        </template>
    </SimpleTable>
</template>

<script>
import SimpleTable from "@/components/core/SimpleTable";
export default {
    components: {
        SimpleTable,
    },
    data() {
        return {
            headers: [
                { text: this.$t("Habilitado"), value: "enable", align: "right", sortable: false },
                { text: this.$t("Data de Nascimento"), value: "extra-column", align: "right", sortable: true },
                { text: this.$t("CPF"), value: "cpf", align: "right", sortable: true },
                { text: this.$t("Nome"), value: "name", align: "right", sortable: true },
            ],
        };
    },
};
</script>